.lineDetail {
  border-bottom: 1px solid #c9c9c9;
  padding-left: 0;
}

.filter-button {
  /* border-bottom: 1px solid black; */
  cursor: pointer;
}

.custom-outline {
  outline: 0;
}

.col-10 input {
  width: 100%;
}

.col-10 textarea {
  width: 100%;
  min-height: 90px;
}

.col-10 select {
  height: 30px;
}

.table-buttons {
  margin-left: 30px;
  margin-right: 30px;
}
