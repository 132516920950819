.admin-header {
  border-bottom: 1px solid #b3b3b3;
  border-top: 5px solid #32b280;
  height: 60px;
  background-color: #ffffff !important;
}

.burger {
  margin: 0px;
  padding: 14px 20px;
  border: 1px solid #2da073;
  background-color: #2da073;
  cursor: pointer;
}
