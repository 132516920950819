.icon-button {
  color: #;
}

.delete-button {
  color: #dc3545;
  cursor: pointer;
}

.edit-button {
  color: #007bff;
  cursor: pointer;
}

.action-button {
  width: 90px;
  margin-bottom: 3px;
}

.indicator {
  height: 39px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
}

.text-indicator {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
