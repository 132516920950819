.btn-add-pym {
  background-color: #32b280;
  margin-left: auto !important;
}

.btn-collapse-pym {
  color: black !important;
  background-color: #e9ecef !important;
  width: 100% !important;
  height: 38px !important;
  text-align: left !important;
  transition: 0.4s !important;
  border: none !important;
}

.is-invalid-edit.form-control.is-invalid {
  background-position: center right calc(1em + 0.1875rem);
}

.input-pym-proof {
  opacity: '0';
  position: 'absolute';
  height: '90px';
  width: '100%';
}

.disabled-div {
  pointer-events: none !important;
  background-color: #e9ecef !important;
}
