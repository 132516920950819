.cancel-button {
  color: #e36367;
  text-decoration: underline;
}
.cancel-button:hover {
  cursor: pointer;
  font-weight: bold;
  color: #cc3d42;
}

.main-text {
  font-size: 14px;
  color: #505050;
  font-weight: bold;
}
.sub-text {
  font-size: 14px;
  color: #6e6e6e;
}
