@import url('https://fonts.googleapis.com/css?family=Open+Sans');

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.focus-none:focus {
  outline: none;
}
@media (max-width: 576px) {
  .m-center {
    text-align: center;
  }
  .m-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .m-obj-auto {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.bg-nav-transparent {
  transition: background-color 0.5s, padding-top 0.5s;
  background-color: transparent !important;
  padding-top: 3rem;
  /* border-bottom: 1px solid #dee2e6; */
  transition: background-color 0.5s, padding-top 0.5s, padding-bottom 0.5s;
}
.bg-nav-white {
  transition: background-color 0.5s, padding-top 0.5s, padding-bottom 0.5s;
  background-color: white !important;
  height: 55.525px;
  /* height: calc(55.575px + (22.425) * (100vw - 1366px) / (1920 - 1366)); */
  border-bottom: 1px solid #dee2e6;
  z-index: 900;
  width: 100vw;
}
@media (min-width: 1367px) {
  .bg-nav-white {
    height: 78px;
  }
}
.navbar-logo {
  width: 150px;
  margin-left: 25px;
}
@media (min-width: 576px) {
  .navbar-logo {
    width: 150px;
  }
}
.general-title {
  /* font-size: calc(16pt + (10.333) * (100vw - 1366px) / (1920 - 1366)); 16pt-24pt, 8px */
  font-size: 20pt;
  color: #53b59f;
  font-weight: bold;
  line-height: 1.2;
}
@media (min-width: 576px) {
  .general-title {
    font-size: 16pt;
  }
}
@media (min-width: 1367px) {
  .general-title {
    font-size: 24pt;
  }
}
.general-title.small {
  /* font-size: calc(12pt + (8) * (100vw - 1366px) / (1920 - 1366)); 12pt-18pt, 5.333px */
  font-size: 12pt;
}
@media (min-width: 1367px) {
  .general-title.small {
    font-size: 15pt;
  }
}
.line-height-15 {
  line-height: 1.5;
}
.line-height-20 {
  line-height: 2;
}
.general-text {
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366))10pt-14pt */
  font-size: 10pt;
}
@media (min-width: 1367px) {
  .general-text {
    font-size: 14pt;
  }
}
.text-gray {
  color: #666666;
}
.text-gray.lter {
  color: #b3b3b3;
}
.text-green {
  color: #53b59f !important;
}
.general-tooltip {
  background-color: #53b59f !important;
}
.tooltip {
  z-index: 500;
}
.tooltip.bottom .tooltip-inner {
  background: #53b59f !important;
}

.tooltip.bottom .arrow:before {
  border-bottom-color: #53b59f !important;
  border-top-color: #53b59f !important;
  /* border-right-color: #53b59f !important; */
}
.tooltip.left .tooltip-inner {
  background: #53b59f !important;
}

.tooltip.left .arrow:before {
  /* border-bottom-color:#53b59f !important;
 border-top-color:#53b59f !important; */
  border-right-color: #53b59f !important;
}

.green-box {
  background-color: #edf8f5;
  border-radius: 10px;
}
.white-box {
  background-color: white;
  border-radius: 4px;
}
.sticky-sidebar {
  position: sticky;
  top: 10vh;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
}
@media (min-width: 1367px) {
  .sticky-sidebar {
    top: 20vh;
  }
}

.btn-rounded-black {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: white;
  background-color: #666666;
  border: none;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
}
@media (min-width: 1367px) {
  .btn-rounded-black {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-black:focus {
  outline: none;
}
.btn-rounded-white-border-green {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: #53b59f;
  background-color: #ffffff;
  border: 3px solid #53b59f;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
}
@media (min-width: 1367px) {
  .btn-rounded-white-border-green {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-green {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: white;
  background-color: #53b59f;
  border: none;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
  transition: background-color 0.3s, color 0.3s;
}
@media (min-width: 1367px) {
  .btn-rounded-green {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-green:hover {
  /* background-color: rgb(247,245,245); */
  background-color: white;
  /* color: #666666; */
  color: #1f335b;
  /* border: 3px solid #53b59f; */
}
.btn-rounded-green:focus {
  outline: none;
}
.btn-rounded-green-border {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: white;
  background-color: #53b59f;
  border: none;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
  transition: background-color 0.3s, color 0.3s;
}
@media (min-width: 1367px) {
  .btn-rounded-green-border {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-green-border:hover {
  background-color: white;
  color: #53b59f;
  border: 2px solid #53b59f;
}
.btn-rounded-green-border:focus {
  outline: none;
}
.square-button {
  cursor: pointer;
  font-weight: bold;
  font-size: 10pt;
  color: white;
  background-color: #53b59f;
  border: none;
  height: 60px;
  border-radius: 10px;
  width: 200pt;
  /* transition: box-shadow .3s; */
}
@media (min-width: 1367px) {
  .square-button {
    height: 80px;
    font-size: 14pt;
  }
}
.square-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.square-button:focus {
  outline: none;
}
.btn-static-white {
  cursor: pointer;
  font-weight: bold;
  font-size: 10pt;
  color: #ffffff;
  background-color: transparent;
  border: 1.5px solid #ffffff;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
}
@media (min-width: 1367px) {
  .btn-static-white {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-static-white:focus {
  outline: none;
}
.btn-rounded-white {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: white;
  background-color: transparent;
  border: 3px solid white;
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
  transition: background-color 0.3s, color 0.3s;
}
@media (min-width: 1367px) {
  .btn-rounded-white {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-white:hover {
  background-color: white;
  color: #666666;
  border: none;
}
.btn-rounded-white:focus {
  outline: none;
}
.btn-rounded-white-border {
  cursor: pointer;
  font-weight: bold;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt; */
  font-size: 10pt;
  color: #b3b3b3;
  background-color: transparent;
  border: 1.5px solid #b3b3b3;
  /* border: none; */
  height: 33pt;
  width: 200pt;
  border-radius: 9999em 9999em 9999em 9999em;
  transition: 0.3s;
}
@media (min-width: 1367px) {
  .btn-rounded-white-border {
    font-size: 14pt;
    height: 40pt;
  }
}
.btn-rounded-white-border:hover {
  background-color: white;
  color: #53b59f;
  border: 1.5px solid #53b59f;
  /* border: none; */
}
.btn-rounded-white-border:focus {
  outline: none;
}

.cross-button {
  /* font-size: calc(12pt + (8) * (100vw - 1366px) / (1920 - 1366));12-18pt */
  font-size: 12pt;
  color: #b3b3b3;
  cursor: pointer;
  transition: color 0.3s;
}
@media (min-width: 1367px) {
  .cross-button {
    font-size: 18pt;
  }
}

.cross-button:hover {
  color: #53b59f;
}

.plus-button {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  /* font-size: calc(12pt + (10.666) * (100vw - 1366px) / (1920 - 1366));12-20pt */
  font-size: 10pt;
  font-weight: 700;
  line-height: 30px;
  border: 2px solid #53b59f;
  background-color: #53b59f !important;
  color: white;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 576px) {
  .plus-button {
    width: 45px;
    height: 45px;
    border-radius: 30px;
    line-height: 45px;
  }
}
@media (min-width: 1367px) {
  .plus-button {
    font-size: 20pt;
  }
}
.plus-button:hover {
  background-color: white !important;
  color: #53b59f;
}

.btn-circle-green {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  text-align: center;
  /* font-size: calc(16pt + (10.666) * (100vw - 1366px) / (1920 - 1366));16-24pt */
  font-size: 12pt;
  font-weight: 700;
  line-height: 1.42857;
  border: 2px solid #53b59f;
  background-color: #53b59f !important;
  color: white;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 576px) {
  .btn-circle-green {
    font-size: 16pt;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 1367px) {
  .btn-circle-green {
    font-size: 24pt;
  }
}

.btn-circle-green:focus {
  box-shadow: none;
  outline: none;
}

.btn-circle-green.active {
  background-color: white !important;
  color: #53b59f;
  border: 2px solid #53b59f;
  box-shadow: none;
  outline: none;
}

.border-green {
  border-color: #53b59f !important;
  border-width: medium;
}

.line-green {
  background-color: #53b59f !important;
  width: 30pt;
  height: 4pt;
  border-radius: 20pt;
}

.sticky-line-green {
  position: absolute;
  left: 14px;
  background-color: #53b59f;
  border-radius: 15pt;
  margin-top: -3px;
  width: 4px;
  height: 30px;
  display: inline-block;
}

@media (min-width: 1367px) {
  .sticky-line-green {
    left: 13px;
    width: 6px;
    margin-top: -7px;
    height: 40px;
  }
}

.tag-gray {
  /* font-size: calc(8pt + (5.333) * (100vw - 1366px) / (1920 - 1366));8-12pt */
  font-size: 12pt;
  font-weight: lighter;
  color: #b3b3b3;
}
.tag-gray.dker {
  color: #666666;
}
.tag-green {
  /* font-size: calc(8pt + (5.333) * (100vw - 1366px) / (1920 - 1366));8-12pt */
  font-size: 8pt;
  color: #53b59f;
}
@media (min-width: 1367px) {
  .tag-green {
    font-size: 12pt;
  }
}
.body-text-gray {
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt  */
  font-size: 14pt;
  color: #727272;
  font-weight: lighter;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .body-text-gray {
    font-size: 10pt;
    line-height: 1.8;
  }
}
@media (min-width: 1367px) {
  .body-text-gray {
    font-size: 14pt;
  }
}

.body-text-gray .lg {
  /* font-size: calc(12pt + (10.666) * (100vw - 1366px) / (1920 - 1366));12-20pt */
  font-size: 12pt;
}
@media (min-width: 1367px) {
  .body-text-gray .lg {
    font-size: 20pt;
  }
}
.body-text-gray .xl {
  /* font-size: calc(16pt + (10.666) * (100vw - 1366px) / (1920 - 1366));16-24pt  */
  font-size: 16pt;
}
@media (min-width: 1367px) {
  .body-text-gray .xl {
    font-size: 24pt;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.link:hover {
  color: #53b59f;
  cursor: pointer;
}

.text-container {
  margin-top: 20px;
  width: 100%;
}
.text-container > .text-input {
  border: none;
  border-bottom: 1px solid #b3b3b3;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt */
  font-size: 10pt;
  color: #666666;
  padding-bottom: 20px;
  width: 100%;
  background-color: transparent;
}
@media (min-width: 1367px) {
  .text-container > .text-input {
    font-size: 14pt;
  }
}
.text-container > .text-input:focus {
  outline: none;
  border-color: #54b59f;
}

.text-container > .text-input::placeholder {
  color: #b3b3b3;
}

.text-container > .text {
  font-weight: bold;
  position: relative;
  top: -80px;
  color: #666666;
  font-size: 10pt;
}
@media (min-width: 1367px) {
  .text-container > .text {
    font-size: 12pt;
  }
}

.text-container input:focus + .text {
  color: #54b59f;
}

.textarea-container {
  margin-top: 20px;
  width: 100%;
}
.textarea-container > .textarea-input {
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  /* font-size: calc(10pt + (5.333) * (100vw - 1366px) / (1920 - 1366));10-14pt */
  font-size: 10pt;
  color: #666666;
  padding: 20px;
  width: 100%;
  resize: none;
}
@media (min-width: 1367px) {
  .textarea-container > .textarea-input {
    font-size: 14pt;
  }
}
.textarea-container > .textarea-input:focus {
  outline: none;
  border-color: #54b59f;
}

.textarea-container > .textarea-input::placeholder {
  color: #b3b3b3;
}

.textarea-container > .text {
  font-weight: bold;
  position: relative;
  top: -150px;
  color: #666666;
  font-size: 10pt;
}
@media (min-width: 1367px) {
  .textarea-container > .text {
    top: -170px;
    font-size: 12pt;
  }
}

.textarea-container textarea:focus + .text {
  color: #54b59f;
}
.filter-green {
  /* filter: invert(58%) sepia(60%) saturate(328%) hue-rotate(116deg) brightness(97%) contrast(86%); */
  filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(213deg)
    brightness(1000%) contrast(104%);
}
.tag-container {
  display: inline-block;
  width: 60px;
  height: 25px;
  background-color: #ccffe7;
  border-radius: 20px;
  color: #53b59f;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
}
@media (min-width: 576px) {
  .tag-container {
    width: 88px;
    height: 34px;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
  }
}
.body-app {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 576px) {
  .m-100 {
    width: 100%;
  }
}

.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}
@media (min-width: 576px) {
  .width-sm-50 {
    width: 50%;
  }
  .width-sm-60 {
    width: 60%;
  }
  .width-sm-70 {
    width: 70%;
  }
  .width-sm-80 {
    width: 80%;
  }
  .width-sm-90 {
    width: 90%;
  }
  .width-sm-100 {
    width: 100%;
  }
}
@media (min-width: 1367px) {
  .width-md-50 {
    width: 50%;
  }
  .width-md-60 {
    width: 60%;
  }
  .width-md-70 {
    width: 70%;
  }
  .width-md-80 {
    width: 80%;
  }
  .width-md-90 {
    width: 90%;
  }
  .width-md-100 {
    width: 100%;
  }
}

.font-8 {
  font-size: 8pt !important;
}
.font-10 {
  font-size: 10pt !important;
}
.font-12 {
  font-size: 12pt !important;
}
.font-14 {
  font-size: 14pt !important;
}
.font-16 {
  font-size: 16pt !important;
}
.font-18 {
  font-size: 18pt !important;
}
.font-20 {
  font-size: 20pt !important;
}
.font-22 {
  font-size: 22pt !important;
}
.font-24 {
  font-size: 24pt !important;
}
.font-26 {
  font-size: 26pt !important;
}
.font-28 {
  font-size: 28pt !important;
}
.font-30 {
  font-size: 30pt !important;
}
.font-32 {
  font-size: 32pt !important;
}
.font-42 {
  font-size: 42pt !important;
}
.font-52 {
  font-size: 52pt !important;
}
.font-62 {
  font-size: 62pt !important;
}
.font-72 {
  font-size: 72pt !important;
}
@media (min-width: 576px) {
  .font-sm-8 {
    font-size: 8pt !important;
  }
  .font-sm-10 {
    font-size: 10pt !important;
  }
  .font-sm-12 {
    font-size: 12pt !important;
  }
  .font-sm-14 {
    font-size: 14pt !important;
  }
  .font-sm-16 {
    font-size: 16pt !important;
  }
  .font-sm-18 {
    font-size: 18pt !important;
  }
  .font-sm-20 {
    font-size: 20pt !important;
  }
  .font-sm-22 {
    font-size: 22pt !important;
  }
  .font-sm-24 {
    font-size: 24pt !important;
  }
  .font-sm-26 {
    font-size: 26pt !important;
  }
  .font-sm-28 {
    font-size: 28pt !important;
  }
  .font-sm-30 {
    font-size: 30pt !important;
  }
  .font-sm-32 {
    font-size: 32pt !important;
  }
  .font-sm-42 {
    font-size: 42pt !important;
  }
  .font-sm-52 {
    font-size: 52pt !important;
  }
  .font-sm-62 {
    font-size: 62pt !important;
  }
  .font-sm-72 {
    font-size: 72pt !important;
  }
}
@media (min-width: 1367px) {
  .font-md-8 {
    font-size: 8pt !important;
  }
  .font-md-10 {
    font-size: 10pt !important;
  }
  .font-md-12 {
    font-size: 12pt !important;
  }
  .font-md-14 {
    font-size: 14pt !important;
  }
  .font-md-16 {
    font-size: 16pt !important;
  }
  .font-md-18 {
    font-size: 18pt !important;
  }
  .font-md-20 {
    font-size: 20pt !important;
  }
  .font-md-22 {
    font-size: 22pt !important;
  }
  .font-md-24 {
    font-size: 24pt !important;
  }
  .font-md-26 {
    font-size: 26pt !important;
  }
  .font-md-28 {
    font-size: 28pt !important;
  }
  .font-md-30 {
    font-size: 30pt !important;
  }
  .font-md-32 {
    font-size: 32pt !important;
  }
  .font-md-42 {
    font-size: 42pt !important;
  }
  .font-md-52 {
    font-size: 52pt !important;
  }
  .font-md-62 {
    font-size: 62pt !important;
  }
  .font-md-72 {
    font-size: 72pt !important;
  }
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(340deg)
    brightness(200%) contrast(102%);
}

.backgroundcolordanger {
  background-color: #fff8f8;
  border: 1px solid #fff8f8;
  border-radius: 5px;
  color: #ef767a;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
