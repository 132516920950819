.text-input {
  padding: 6px;
  border-radius: 3px;
  border-color: lightgray;
  border-width: 1px;
}
textarea:focus,
input:focus {
  outline: none;
  box-shadow: 0px 0px 1px 3px rgba(168, 203, 237, 1);
}
