.sidebar-body {
  position: fixed;
  top: 48px;
  left: 0px;
  height: 94%;
  z-index: 1000;
  background-color: #ffffff;
  width: 0px;
  overflow-x: hidden;
  transition: 0.25s;
  transition-timing-function: ease-out;
}
.sidebar-header {
  color: #666666;
  font-weight: bold;
}
.sidebar-header.active {
  color: white;
  background-color: #53b59f;
  font-weight: bold;
}
.sidebar-header:hover {
  color: white;
  background-color: #53b59f;
  cursor: pointer;
}
.sidebar-link {
  color: #666666;
  border-top: 1px solid #e8e8e8;
}
.sidebar-link:hover {
  color: #53b59f;
  background-color: #e6e6e6;
  cursor: pointer;
}
.sidebar-link.active {
  color: #53b59f;
  background-color: #e6e6e6;
  cursor: pointer;
}
.sidebar-second-title {
  margin-left: 37px;
}
